@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gugi&family=Lekton:ital,wght@0,400;0,700;1,400&display=swap");

* {
  font-family: "Lekton", serif !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #0d1321; */
}

h1,
h1.ant-typography,
h2,
h2.ant-typography {
  font-family: "Gugi", serif !important;
  font-weight: 400 !important;
  font-style: normal;
}

h3,
h3.ant-typography,
h4,
h4.ant-typography,
h5,
h5.ant-typography,
h6,
h6.ant-typography,
.ant-btn {
  font-family: "Lekton", serif !important;
  font-weight: 700 !important;
  font-style: normal;
}

p,
a,
.ant-typography {
  font-family: "Lekton", serif !important;
  font-weight: 400 !important;
  font-style: normal;
}

header a.ant-btn-link {
  color: #0d1321 !important;
}

img.img-grayscale {
  filter: contrast(0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-layout .ant-layout-header {
  padding: 0 1rem;
}
@media (min-width: 476px) {
  .app-layout .ant-layout-header {
    padding: 0 1rem;
  }
}
@media (min-width: 768px) {
  .app-layout .ant-layout-header {
    padding: 0 1rem;
  }
}
@media (min-width: 992px) {
  .app-layout .ant-layout-header {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .app-layout .ant-layout-header {
    padding: 0 3rem;
  }
}

.app-layout .ant-layout-content {
  height: calc(100vh - 135px);
  overflow: auto;
  padding: 1rem;
}
@media (min-width: 476px) {
  .app-layout .ant-layout-content {
    padding: 1rem;
  }
}
@media (min-width: 768px) {
  .app-layout .ant-layout-content {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .app-layout .ant-layout-content {
    padding: 1.5rem 3rem;
  }
}
@media (min-width: 1200px) {
  .app-layout .ant-layout-content {
    padding: 1.5rem 3rem;
  }
}

.container-fluid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}
